import { graphql, useStaticQuery } from 'gatsby'

const useContentfulSection = () => {
  const { allContentfulSection } = useStaticQuery(
    graphql`
      query ContentfulSections {
        allContentfulSection {
          nodes {
            contentful_id
            node_locale
            title
            description {
              childMarkdownRemark {
                excerpt
                html
              }
            }
            references {
              contentful_id
              title
              description
              youTubeLink
              page {
                slug
                ancestor {
                  slug
                }
              }
              media {
                contentful_id
                title
              }
              poster {
                contentful_id
              }
            }
          }
        }
      }
    `
  )
  return allContentfulSection
}

export default useContentfulSection
