import { theme, styled } from '../../shared/theme'

export const Container = styled.header`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 460px;
  background-color: ${theme.colors.white};
  z-index: 1;
  height: ${props => props.height};
`
