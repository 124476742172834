import { graphql, useStaticQuery } from 'gatsby'

const useContentfulAsset = () => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query ContentfulAsset {
        allContentfulAsset(filter: { file: { contentType: { ne: null } } }) {
          nodes {
            contentful_id
            file {
              url
              contentType
            }
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
            section: fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
            poster: fluid(maxWidth: 640, maxHeight: 360, quality: 80) {
              ...GatsbyContentfulFluid_noBase64
            }
            management: fluid(
              maxWidth: 200
              maxHeight: 200
              quality: 80
              cropFocus: CENTER
              resizingBehavior: SCALE
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
            reference: fixed(
              width: 100
              height: 100
              quality: 100
              cropFocus: CENTER
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    `
  )
  return allContentfulAsset
}

export default useContentfulAsset
