import { theme, styled } from '../../shared/theme'

export const Section = styled.section`
  position: relative;
  display: flex;
  margin: auto;
  background-color: ${theme.colors.black};
  z-index: 1;
  width: 100%;
  height: 400px;
`

export const MapWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  margin: auto;
  background: ${theme.colors.black};
  height: inherit;

  a,
  .gmnoprint,
  .gm-style-cc {
    display: none !important;
  }
`
