import { rgba } from 'polished'
import { theme, styled } from '../../shared/theme'

export const Player = styled.div`
  position: relative;
  overflow: hidden;

  &:fullscreen {
    max-width: none;
    width: 100%;
    border: 0;
  }

  &:-webkit-full-screen {
    max-width: none;
    width: 100%;
  }

  &:hover {
    & div {
      transform: translateY(0);
    }
  }
`

export const StartPlay = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  background-color: ${rgba(theme.colors.primary, 0.6)};
  z-index: 10;
  cursor: pointer;
  border-radius: 4px;
  width: 70px;
  height: 50px;

  &:hover {
    background-color: ${theme.colors.primary}40;
  }
`

export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 1s ease-in-out;
  background-color: ${rgba(theme.colors.black, 0.3)};
  z-index: 5;
  height: 70px;
  padding: 20px 20px 10px;
  transform: ${props =>
    props.playing && props.playing === true
      ? `translatey(0)`
      : `translatey(70px)`};
`

export const Progress = styled.div`
  position: relative;
  background-color: ${rgba(theme.colors.white, 0.6)};
  cursor: ew-resize;
  display: flex;
  flex: 10;
  flex-basis: 100%;
  height: 4px;
`

export const ProgressBar = styled.div`
  background-color: ${theme.colors.primary};
  flex: 0;
  flex-basis: 50%;
  width: 50%;
`

export const FlexWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.div`
  font-weight: ${theme.fontWeight.medium};
  text-transform: uppercase;
  color: ${theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  width: 100%;
  flex: auto;
  margin-right: 1em;
  letter-spacing: 2px;
  line-height: 40px;
  font-size: 16px;
`

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
`

export const ControlButton = styled.button`
  border: 0;
  background-color: transparent;
  color: ${theme.colors.white};
  line-height: 1;
  transition: color 0.5s;
  cursor: pointer;
  font-size: 12px;

  @media ${theme.mq.fullhd} {
    font-size: 14px;
  }

  svg {
    display: block;
  }

  &:active,
  &:hover,
  &:focus {
    color: ${theme.colors.primary};
  }
`
