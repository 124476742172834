import styled from '@emotion/styled'
import { rgba } from 'polished'
import BackgroundImage from 'gatsby-background-image'
import { theme, css } from '../../shared/theme'

const dotSize = 60
const dotStrokeWidth = 1.5
const dotBorderMultiplier = 0.18
const dotInnerMultiplier = 0.9

export const BgImg = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: ${theme.colors.white};
`

export const Excerpt = styled.div`
  position: absolute;
  bottom: 27px;
  left: 1em;
  display: none;

  @media ${theme.mq.tablet} {
    display: block;
  }

  @media ${theme.mq.desktop} {
    left: 45px;
  }

  > div {
    opacity: 0.3;
    transition: opacity 1s;
    cursor: pointer;
    margin-bottom: 10px;
    width: 210px;

    @media ${theme.mq.tablet} {
      width: 400px;
    }

    @media ${theme.mq.desktop} {
      width: 600px;
    }

    @media ${theme.mq.fullhd} {
      width: 800px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 15px;
      height: 4px;
      background-color: ${theme.colors.primary};
    }

    &:hover,
    &:focus,
    &.is-active {
      opacity: 1;
    }
  }
`

export const Title = styled.h4`
  color: ${theme.colors.white};
  font-weight: ${theme.fontWeight.bold};
  text-transform: uppercase;
  margin: 0;
  line-height: 30px;
  letter-spacing: 2px;
  padding-top: 10px;

  /* soft ellipsis */
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media ${theme.mq.fullhd} {
    font-size: 22px;
    line-height: 34px;
    letter-spacing: 3px;
    padding-top: 15px;
  }
`

export const Dots = styled.div`
  position: absolute;
  bottom: 17px;
  right: 17px;
  line-height: 1;
`

const activeDot = css`
  svg {
    animation-play-state: paused;

    circle {
      animation-play-state: paused;
      stroke: none !important;
    }
  }

  &::before {
    border: none;
  }

  &::after {
    background-color: ${theme.colors.primary};
  }
`

export const Dot = styled.div`
  display: inline-block;
  position: relative;
  width: ${dotSize}px;
  height: ${dotSize}px;
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20em;
    z-index: 2;
  }

  &::before {
    width: ${dotSize - dotSize * dotBorderMultiplier}px;
    height: ${dotSize - dotSize * dotBorderMultiplier}px;
    transition: border 2s, background-color 2s;
    border: none;
  }

  &::after {
    width: ${dotSize - dotSize * dotInnerMultiplier}px;
    height: ${dotSize - dotSize * dotInnerMultiplier}px;
    background-color: ${rgba(theme.colors.white, 0.9)};
    transition: background-color 2s;
  }

  svg {
    position: relative;
    width: inherit;
    height: inherit;
    transform-origin: center center;

    circle {
      stroke-dasharray: 50, 200;
      stroke-dashoffset: -10;
      stroke-linecap: round;
      stroke-width: ${dotStrokeWidth};
      stroke: none;
      fill: none;
      transition: stroke 2s;
    }
  }

  &:hover,
  &:focus {
    &::before {
      background-color: ${rgba(theme.colors.white, 0.1)};
      border-color: ${rgba(theme.colors.primary, 0.4)};
    }

    &::after {
      background-color: ${theme.colors.primary};
    }
  }

  ${props => (props.active && props.active === true ? activeDot : null)}
`
