import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { FaLinkedinIn } from 'react-icons/fa'
import useContentfulPersonnel from '../../hooks/use-contentful-personnel'
import useContentfulAsset from '../../hooks/use-contentful-asset'
import {
  Columns,
  ImgColumn,
  Column,
  Circle,
  Title,
  Span,
  Excerpt,
  LinkedIn
} from './styles'

const Management = ({ locale }) => {
  const { nodes: personnel } = useContentfulPersonnel()
  const { nodes: assets } = useContentfulAsset()
  const results = personnel.filter(({ node_locale }) => node_locale === locale)

  return (
    <>
      {results.map(person => {
        const asset = assets.find(
          ({ contentful_id }) => contentful_id === person.photo.contentful_id
        )

        return (
          <Columns key={person.contentful_id}>
            <ImgColumn>
              <Circle>
                <Img fluid={asset.management} />
              </Circle>
            </ImgColumn>
            <Column>
              <Title>
                {person.name}
                <Span />
                {person.role}
              </Title>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: person.content.childMarkdownRemark.html
                }}
              />
              {person.linkedIn && (
                <LinkedIn href={person.linkedIn} rel="noopener">
                  <FaLinkedinIn />
                </LinkedIn>
              )}
            </Column>
          </Columns>
        )
      })}
    </>
  )
}

Management.propTypes = {
  locale: PropTypes.string.isRequired
}

export default Management
