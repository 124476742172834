import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useContentfulPage from '../../hooks/use-contentful-page'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { buildPath } from '../../shared/helpers'
import {
  Columns,
  DateColumn,
  Column,
  Published,
  Time,
  Year,
  Content,
  Title,
  Excerpt,
  ReadAll
} from './styles'

const News = ({ slug, locale }) => {
  const { nodes: pages } = useContentfulPage()
  const { nodes: settings } = useContentfulSettings()
  const { page } = pages.find(node => node.slug === slug)
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations

  // Can show no results ..
  if (!page) return ''

  return (
    <>
      {page.map(entry => {
        const link = buildPath(entry.node_locale, entry)

        return (
          <Columns key={entry.contentful_id}>
            <DateColumn>
              <Published>
                <Time dateTime={entry.updatedAt}>
                  {entry.shortDate}
                  <Year>{entry.year}</Year>
                </Time>
              </Published>
            </DateColumn>
            <Column>
              <Content>
                <Title>
                  <Link to={link}>{entry.title}</Link>
                </Title>
                <Excerpt>{entry.content.childMarkdownRemark.excerpt}</Excerpt>
                <ReadAll>
                  <Link to={link}>{t.readAll}</Link>
                </ReadAll>
              </Content>
            </Column>
          </Columns>
        )
      })}
    </>
  )
}

News.propTypes = {
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

export default News
