import { styled } from '../../shared/theme'

export const MasonryDiv = styled.div`
  display: grid;
  grid-auto-flow: column dense;
  grid-gap: ${props => props.gap};
  grid-template-columns: repeat(auto-fit, 1fr);
`

export const Col = styled.div`
  display: grid;
  grid-gap: ${props => props.gap};
  grid-auto-rows: max-content;
`
