import React from 'react'
import PropTypes from 'prop-types'
import useContentfulAsset from '../../hooks/use-contentful-asset'
import useMedia from '../../hooks/use-media'
import { theme } from '../../shared/theme'
import Masonary from '../masonary'
import { Section, Figure, Image } from './styles'

const Gallery = ({ images }) => {
  const { nodes: assets } = useContentfulAsset()
  const ids = images.map(({ contentful_id }) => contentful_id)

  const results = assets.filter(
    asset => ids.includes(asset.contentful_id) && asset.fluid
  )

  const minWidth = useMedia(
    [theme.mq.fullhd, theme.mq.desktop, theme.mq.tablet],
    [800, 600, 400],
    300
  )

  return (
    <Section>
      <Masonary gap="1em" minWidth={minWidth}>
        {results.map(record => (
          <Figure key={record.contentful_id} minWidth={minWidth}>
            <Image fluid={record.fluid} />
          </Figure>
        ))}
      </Masonary>
    </Section>
  )
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string
    })
  ).isRequired
}

export default Gallery
