import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useCookies } from 'react-cookie'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { Container, Paragraph, Close } from './styles'

const CookieConsent = ({ locale }) => {
  const { nodes: settings } = useContentfulSettings()
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations

  const [cookies, setCookie] = useCookies(['cc'])
  const expire = new Date().getTime() + 60 * 60 * 24 * 1000 * 365

  const handleClose = () => {
    setCookie('cc', 1, { path: '/', expires: new Date(expire) })
  }

  return (
    <Container closed={!!cookies.cc}>
      <Paragraph>
        {t.consent.uses}
        <Link to={t.consent.link}>{t.consent.terms}</Link>
      </Paragraph>
      <Close aria-label="Close" type="button" onClick={handleClose} />
    </Container>
  )
}

CookieConsent.propTypes = {
  locale: PropTypes.string.isRequired
}

export default CookieConsent
