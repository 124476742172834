/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import { theme, styled } from '../shared/theme'
import { getLang } from '../shared/helpers'
import SEO from '../components/seo'
import Header from '../components/header'
import CookieConsent from '../components/cookie-consent'
import Footer from '../components/footer'
import Sidebar from '../components/sidebar'
import Breadcrumb from '../components/breadcrumb'
import Authored from '../components/authored'
import Content from '../components/content'
import Management from '../components/management'
import References from '../components/references'
import News from '../components/news'
import Section from '../components/section'
import Gallery from '../components/gallery'
import Contact from '../components/contact'

const Wrapper = styled.section`
  position: relative;
  min-height: 200px;
  padding-top: 30px;
  z-index: 2; /* for map and bg video */
  background-color: ${theme.colors.white};
  margin-bottom: 0;
`

const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin: auto;
  max-width: ${theme.breakpoints[3]}px;
  padding: 1em;

  @media ${theme.mq.desktop} {
    padding: 0;
  }

  @media ${theme.mq.fullhd} {
    max-width: ${theme.breakpoints[4]}px;
  }
`

const Title = styled.h1`
  color: ${theme.colors.text};
  font-weight: ${theme.fontWeight.bold};
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  margin: 0.5em auto 0.75em;

  @media ${theme.mq.mobileL} {
    font-size: 24px;
  }

  @media ${theme.mq.tablet} {
    font-size: 32px;
    letter-spacing: 2px;
    margin: 0.5em auto 1em;
  }

  @media ${theme.mq.laptop} {
    font-size: 38px;
  }

  @media ${theme.mq.desktop} {
    font-size: 42px;
    margin: 1em auto;
  }

  @media ${theme.mq.fullhd} {
    font-size: 64px;
  }
`

export default ({ location, data }) => {
  const {
    locale,
    slug,
    title,
    description,
    content,
    slides,
    sections,
    gallery,
    template,
    updatedAt
  } = data.sitePage.context

  const lang = getLang(locale) // en -> en_US
  const isIndex = ['/', '/sv'].includes(location.pathname)

  return (
    <>
      <SEO
        lang={lang}
        title={title}
        description={description}
        isIndex={isIndex}
      />
      <Header slides={slides} />
      <Wrapper>
        <Container>
          <Breadcrumb url={location.pathname} />
          {template !== 'Contact' && (
            <>
              <Authored updatedAt={updatedAt} excerpt={description} />
              <Title>{title}</Title>
            </>
          )}

          {content && <Content content={content} />}
          {template === 'News' && <News slug={slug} locale={locale} />}
          {template === 'Management' && <Management locale={locale} />}
          {template === 'References' && (
            <References slug={slug} locale={locale} />
          )}
        </Container>

        {template === 'Contact' && <Contact slug={slug} locale={locale} />}

        {sections &&
          sections.map(section => (
            <Section
              locale={locale}
              section={section}
              key={section.contentful_id}
            />
          ))}

        {gallery && <Gallery images={gallery} />}
      </Wrapper>

      <Footer />
      <Sidebar locale={locale} />
      <CookieConsent locale={locale} />
    </>
  )
}

export const query = graphql`
  query($path: String!) {
    sitePage(path: { eq: $path }) {
      context {
        contentful_id
        locale
        slug
        slugs {
          slug
          node_locale
        }
        ancestor {
          slug
        }
        title
        description
        template
        updatedAt
        content {
          childMarkdownRemark {
            excerpt
            html
          }
        }
        slides {
          contentful_id
          title
          media {
            contentful_id
          }
        }
        sections {
          contentful_id
          title
          description {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          references {
            contentful_id
            title
            description
            media {
              contentful_id
            }
          }
        }
        gallery {
          contentful_id
        }
      }
    }
  }
`
