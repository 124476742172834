import React from 'react'
import PropTypes from 'prop-types'
import useContentfulAsset from '../../hooks/use-contentful-asset'
import Navbar from '../navbar'
import Slides from '../slides'
import VideoSlide from '../video-slide'
import { Container } from './styles'

const Header = ({ slides }) => {
  const { nodes } = useContentfulAsset()
  const results = slides.map(({ title, media }) => {
    return {
      ...nodes.find(node => node.contentful_id === media.contentful_id),
      ...{ title, id: media.contentful_id }
    }
  })
  const video = results.find(({ file }) => file.contentType.startsWith('video'))
  const height = slides.length > 1 || video ? '100vh' : '460px'

  return (
    <Container height={height}>
      <Navbar />
      {video ? <VideoSlide video={video} /> : <Slides slides={results} />}
    </Container>
  )
}

Header.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      contentful_id: PropTypes.string,
      title: PropTypes.string,
      media: PropTypes.shape({
        contentful_id: PropTypes.string
      })
    })
  ).isRequired
}

export default Header
