import { graphql, useStaticQuery } from 'gatsby'

const useContentfulMenu = () => {
  const { allContentfulMenu } = useStaticQuery(
    graphql`
      query ContentfulMenus {
        allContentfulMenu {
          nodes {
            contentful_id
            objectId
            node_locale
            title
            childs {
              ... on ContentfulMenu {
                contentful_id
                node_locale
                title
                page {
                  slug
                }
                childs {
                  ... on ContentfulPage {
                    contentful_id
                    slug
                    title
                    ancestor {
                      slug
                    }
                  }
                }
              }
              ... on ContentfulExternalLinkItem {
                contentful_id
                title
                icon
                url
              }
              ... on ContentfulPage {
                contentful_id
                slug
                title
                ancestor {
                  slug
                }
              }
            }
          }
        }
      }
    `
  )
  return allContentfulMenu
}

export default useContentfulMenu
