import { theme, styled } from '../../shared/theme'

export const Columns = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0 0 2em;

  @media ${theme.mq.tablet} {
    padding: 0 0 2em;
  }

  @media ${theme.mq.desktop} {
    padding: 0 0 3em;
  }
`

export const ImgColumn = styled.div`
  position: relative;
  text-align: center;
  flex: none;
  width: 16.6666%;
`

export const Column = styled.div`
  position: relative;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 0 0 2em;
`

export const Circle = styled.div`
  position: relative;
  border-radius: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
`

export const Title = styled.h2`
  font-weight: ${theme.fontWeight.bold};
  margin: 0;
  font-size: 18px;
  line-height: 1;

  @media ${theme.mq.tablet} {
    font-size: 20px;
  }

  @media ${theme.mq.desktop} {
    font-size: 24px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 32px;
  }
`

export const Span = styled.span`
  color: ${theme.colors.primary};
  display: block;

  @media ${theme.mq.tablet} {
    display: inline-block;
    padding: 0 10px;
  }

  &::before {
    content: '//';
    display: none;

    @media ${theme.mq.tablet} {
      display: inline-block;
    }
  }
`

export const Excerpt = styled.p`
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 21px;

  @media ${theme.mq.desktop} {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 27px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const LinkedIn = styled.a`
  color: ${theme.colors.linkedin};
  transition: opacity 0.5s;
  font-size: 14px;

  @media ${theme.mq.tablet} {
    font-size: 16px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.85;
  }
`
