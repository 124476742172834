import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { rgba } from 'polished'
import { theme, styled, css } from '../../shared/theme'

export const Nav = styled.nav`
  display: flex;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: all 0.2s ease-in-out;
  text-shadow: 0 1px 1px ${rgba(theme.colors.black, 0.1)};
  background-color: ${theme.colors.white};
  height: 60px;

  @media ${theme.mq.laptop} {
    margin: 45px 45px 0;
  }

  @media ${theme.mq.fullhd} {
    height: 90px;
  }
`

export const LocaleChoices = styled.div`
  display: flex;
  position: absolute;
  top: 18px;
  right: 40px;
  padding: 5px;

  @media ${theme.mq.laptop} {
    background-color: ${rgba(theme.colors.black, 0.8)};
    top: -24px;
    right: 0;
  }

  @media ${theme.mq.fullhd} {
    top: -30px;
  }
`

export const Button = styled.button`
  border: 0;
  padding: 0;
  font-weight: ${theme.fontWeight.medium};
  text-transform: uppercase;
  transition: color 0.5s;
  color: ${theme.colors.black};
  background-color: ${theme.colors.white};
  cursor: pointer;
  font-size: 12px;

  &:hover,
  &:focus,
  &.is-active {
    color: ${theme.colors.primary};
  }

  @media ${theme.mq.laptop} {
    color: ${rgba(theme.colors.white, 0.6)};
    font-size: 9px;
    font-weight: ${theme.fontWeight.bold};
    background-color: transparent;

    &:hover,
    &:focus,
    &.is-active {
      color: ${theme.colors.white};
    }
  }

  @media ${theme.mq.fullhd} {
    font-size: 14px;
  }

  &:first-of-type {
    margin: 0 13px 0 0;
  }
`

export const Brand = styled.div`
  position: relative;
  display: flex;
  width: 110px;
  height: auto;
  align-items: center;
  transition: width 0.3s ease;
  flex-shrink: 0;
  padding-left: 15px;

  @media ${theme.mq.laptop} {
    padding-left: 20px;
    width: 135px;
  }

  @media ${theme.mq.fullhd} {
    padding-left: 30px;
    width: 180px;
  }
`

export const Bars = styled.button`
  position: absolute;
  right: 0.75rem;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  user-select: none;
  cursor: pointer;

  @media ${theme.mq.laptop} {
    display: none;
  }

  &:active,
  &:focus,
  &:hover {
    + span {
      border-top-color: ${theme.colors.gray};

      &::after,
      &::before {
        background-color: ${theme.colors.gray};
      }
    }
  }
`

const open = css`
  border-top: 3px solid transparent;

  &::after,
  &::before {
    transition: transform 0.5s ease-in-out;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

export const Bar = styled.span`
  position: relative;
  display: block;
  width: 20px;
  margin: 28px 0;
  border-top: 4px solid ${theme.colors.gray};

  &::after,
  &::before {
    content: '';
    display: block;
    background-color: ${theme.colors.gray};
    height: 4px;
    width: 20px;
    position: absolute;
    top: -10px;
    transform: rotate(0);
    transform-origin: 7%;
    transition: transform 0.2s ease-in-out;
  }

  &::after {
    top: 2px;
  }

  ${props => props.open && open}
`

export const Menu = styled.div`
  display: none;

  @media ${theme.mq.laptop} {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    padding-right: 15px;
  }

  @media ${theme.mq.desktop} {
    padding-right: 8px;
  }
`

export const MenuList = styled.ul`
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;

  ul {
    display: none;
    margin: 0;
    padding: 0;

    ul {
      left: 100%;
      top: 0;
    }
  }

  li {
    position: relative;

    &:hover {
      > ul {
        display: block;
        position: absolute;
        z-index: 2;
      }

      li {
        text-align: left;
        background-color: ${theme.colors.white};
        list-style: none;

        a {
          text-decoration: none;
          padding: 0 1em;
          max-width: 200px;
          line-height: 50px;

          @media ${theme.mq.fullhd} {
            padding: 0 1em;
            max-width: 400px;
            line-height: 60px;
          }

          &:hover,
          &:focus,
          &:active,
          &.is-active {
            background-color: ${theme.colors.primary};
          }

          &.is-active {
            user-select: none;
            cursor: default;
          }
        }
      }
    }
  }
`

export const A = styled(OutboundLink)`
  position: relative;
  display: block;
  cursor: pointer;
  background-color: ${theme.colors.white};
  width: 100%;

  li & {
    position: relative;
    display: none;

    @media ${theme.mq.desktop} {
      display: block;
      padding: 0 20px;
    }

    @media ${theme.mq.fullhd} {
      padding: 0 30px;
    }
  }

  &::before {
    visibility: hidden;
  }

  svg {
    transition: fill 0.5s;
  }

  &:focus,
  &:hover {
    svg {
      fill: ${props => theme.colors[props.color]};
    }
  }
`

export const Li = styled.li`
  display: block;
  text-align: center;
  max-width: 200px;

  @media ${theme.mq.fullhd} {
    max-width: 300px;
  }

  a {
    color: ${theme.colors.text};
    font-weight: ${theme.fontWeight.medium};
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none;
    padding: 0 5px;
    line-height: 60px;

    /* soft ellipsis */
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media ${theme.mq.desktop} {
      padding: 0 10px;
    }

    @media ${theme.mq.fullhd} {
      font-size: 18px;
      padding: 0 20px;
      line-height: 90px;
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: ${theme.colors.primary};
      }
    }

    &.is-active {
      user-select: none;
      cursor: default;
    }
  }
`
