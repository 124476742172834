import React from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from './styles'

const Content = ({ content }) => (
  <Paragraph
    dangerouslySetInnerHTML={{ __html: content.childMarkdownRemark.html }}
  />
)

Content.propTypes = {
  content: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string
    })
  }).isRequired
}

export default Content
