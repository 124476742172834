import { graphql, useStaticQuery } from 'gatsby'

const useContentfulPersonnel = () => {
  const { allContentfulPersonnel } = useStaticQuery(
    graphql`
      query ContentfulPersonnel {
        allContentfulPersonnel {
          nodes {
            contentful_id
            node_locale
            name
            role
            linkedIn
            updatedAt
            content {
              childMarkdownRemark {
                html
              }
            }
            photo {
              contentful_id
            }
          }
        }
      }
    `
  )
  return allContentfulPersonnel
}

export default useContentfulPersonnel
