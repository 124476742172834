import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Nav, Ul, Li } from './styles'

const createHistory = url => {
  const paths = url.split('/').filter(Boolean)
  return [
    ...paths.map((path, key) => {
      return {
        key: `crumb-${key}`,
        to: `/${paths.slice(0, key + 1).join('/')}`,
        title: path
      }
    })
  ]
}

const Breadcrumb = ({ url }) => (
  <Nav aria-label="breadcrumb" role="navigation">
    <Ul>
      {createHistory(url).map(({ to, title, key }) => (
        <Li key={key}>
          <Link to={to} activeClassName="active">
            {title}
          </Link>
        </Li>
      ))}
    </Ul>
  </Nav>
)

Breadcrumb.propTypes = {
  url: PropTypes.string.isRequired
}

export default Breadcrumb
