import { theme, styled } from '../../shared/theme'

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  font-size: 14px;

  @media ${theme.mq.tablet} {
    flex-flow: row;
  }

  @media ${theme.mq.fullhd} {
    font-size: 20px;
    line-height: 1.2;
  }
`

export const Column = styled.div`
  @media ${theme.mq.tablet} {
    max-width: 75%;
  }

  @media ${theme.mq.desktop} {
    max-width: 50%;
  }
`

export const Time = styled.time`
  display: block;

  @media ${theme.mq.tablet} {
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    font-weight: ${theme.fontWeight.bold};
    padding: 2px 5px;
  }

  @media ${theme.mq.fullhd} {
    padding: 6px 10px;
  }
`

export const Excerpt = styled(Column)`
  color: ${theme.colors.gray};
  font-style: italic;
  font-weight: ${theme.fontWeight.light};
  width: 100%;

  @media ${theme.mq.tablet} {
    box-shadow: inset 0 4px 0 0 ${theme.colors.primary};
    padding: 0 0 0.5rem 0;
  }
`

export const P = styled.p`
  display: block;
  letter-spacing: 1.75px;
`
