import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BgImg, Excerpt, Title, Dots, Dot } from './styles'

const Slides = ({ slides }) => {
  const [index, setIndex] = useState(0)

  const slideTo = key => setIndex(key)
  let current = slides[index]

  if (!current) {
    setIndex(0)
    ;[current] = slides
  }

  return (
    <BgImg Tag="div" fluid={current.fluid}>
      {slides.length > 1 && (
        <>
          <Excerpt>
            {slides.map(({ id, title }, key) => (
              <div
                key={id}
                tabIndex={key}
                role="button"
                className={key === index ? 'is-active' : ''}
                onClick={() => slideTo(key)}
                onKeyPress={() => slideTo(key)}
              >
                <Title>{title}</Title>
              </div>
            ))}
          </Excerpt>
          <Dots>
            {slides.map(({ id }, key) => (
              <Dot
                key={id}
                role="button"
                active={key === index}
                onClick={() => slideTo(key)}
                onKeyPress={() => slideTo(key)}
              >
                <svg viewBox="25 25 50 50">
                  <circle cx="50" cy="50" r="20" />
                </svg>
              </Dot>
            ))}
          </Dots>
        </>
      )}
    </BgImg>
  )
}

Slides.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      contentful_id: PropTypes.string,
      title: PropTypes.string,
      fluid: PropTypes.shape({
        src: PropTypes.string
      })
    })
  ).isRequired
}

export default Slides
