import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Fa from 'react-icons/fa'
import ContentConsumer from '../../context/content'
import useContentfulMenu from '../../hooks/use-contentful-menu'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { getChild } from '../../shared/helpers'
import {
  Overlay,
  Aside,
  Search,
  SearchIcon,
  Label,
  Input,
  SearchControl,
  Nav,
  NavTitle,
  Ul,
  Li,
  SubUl,
  SubLi,
  InternalLink,
  A,
  Span
} from './styles'

const buildMenu = node => {
  return [...node.childs.map(child => getChild(node.node_locale, child))]
}

const getMenu = (locale, nodes, name) => {
  return buildMenu(
    nodes.find(
      ({ objectId, node_locale }) => objectId === name && node_locale === locale
    )
  )
}

const NavLink = ({ path, title }) => (
  <InternalLink to={path} activeClassName="is-active">
    {title}
  </InternalLink>
)

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const SocialLink = ({ url, title, icon }) => {
  const Icon = Fa[icon]
  return (
    <A href={url} target="_blank" rel="noopener noreferrer">
      <Icon />
      <Span>{title}</Span>
    </A>
  )
}

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

const SubNav = ({ locale, link }) => {
  const pageLink = [locale === 'en' ? null : locale, link.page.slug]
    .filter(Boolean)
    .join('/')

  return (
    <>
      <InternalLink to={`/${pageLink}`}>{link.title}</InternalLink>
      <SubUl>
        {link.childs.map(({ key, path, title }) => (
          <SubLi key={key}>
            <NavLink path={path} title={title} activeClassName="is-active" />
          </SubLi>
        ))}
      </SubUl>
    </>
  )
}

SubNav.propTypes = {
  locale: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    page: PropTypes.shape({
      slug: PropTypes.string
    }),
    childs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        path: PropTypes.string,
        title: PropTypes.string
      })
    )
  }).isRequired
}

const Sidebar = ({ locale }) => {
  const { nodes: menus } = useContentfulMenu()
  const { nodes: settings } = useContentfulSettings()

  const [searchValue, setSearchValue] = useState('')
  const handleSearchInputChanges = e => setSearchValue(e.target.value)

  const applyFilter = results => {
    return [
      ...results.filter(link => {
        if (searchValue === '') return link
        const search = searchValue.normalize().trim()
        const title = link.title.normalize().trim()
        return title.match(search)
      })
    ]
  }

  const results = getMenu(locale, menus, 'index')
  const filteredResults = applyFilter(results)
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations

  return (
    <ContentConsumer>
      {({ state, set }) => (
        <>
          <Aside open={state.isSidebarOpen}>
            <Search>
              <Label htmlFor="search">
                <SearchIcon show={searchValue === '' ? 1 : 0} />
              </Label>
              <SearchControl>
                <Input
                  placeholder={t.search}
                  type="text"
                  id="search"
                  value={searchValue}
                  onChange={handleSearchInputChanges}
                />
              </SearchControl>
            </Search>
            <Nav>
              <NavTitle>Index</NavTitle>
              <Ul>
                {filteredResults.map(link => (
                  <Li key={link.key}>
                    {link.path && (
                      <NavLink path={link.path} title={link.title} />
                    )}
                    {link.url && (
                      <SocialLink
                        url={link.url}
                        title={link.title}
                        icon={link.icon}
                      />
                    )}
                    {link.childs && <SubNav locale={locale} link={link} />}
                  </Li>
                ))}
              </Ul>
            </Nav>
          </Aside>
          <Overlay
            show={state.isSidebarOpen}
            onClick={() => set({ isSidebarOpen: false })}
          />
        </>
      )}
    </ContentConsumer>
  )
}

Sidebar.propTypes = {
  locale: PropTypes.string.isRequired
}

export default Sidebar
