import React, { useState, createRef } from 'react'
import PropTypes from 'prop-types'
import safeGet from 'lodash.get'
import { FaPlay, FaPause, FaArrowsAlt } from 'react-icons/fa'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { transparentPixelSrc } from '../../shared/helpers'
import {
  Player,
  StartPlay,
  Video,
  Controls,
  Progress,
  ProgressBar,
  FlexWrap,
  Title,
  ControlButton
} from './styles'

const VideoPlayer = ({ locale, src, title, poster }) => {
  const { nodes: settings } = useContentfulSettings()
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations
  const posterSrc = safeGet(poster, 'poster.src', transparentPixelSrc)

  const playerRef = createRef()
  const videoRef = createRef()
  const progressRef = createRef()

  const [isPlaying, setPlay] = useState(false)
  const [progressActivity, setProgressActivity] = useState(0)
  const [isMouseOver, setMouseOver] = useState(true)

  const handleScrub = ev => {
    const video = videoRef.current
    const progress = progressRef.current
    const scrubTime =
      (ev.nativeEvent.offsetX / progress.offsetWidth) * video.duration
    if (!Number.isNaN(scrubTime)) video.currentTime = scrubTime
  }

  const handlePlay = () => {
    const video = videoRef.current
    if (video.paused) video.play()
    else video.pause()
    setPlay(!isPlaying)
  }

  const handleProgress = () => {
    const video = videoRef.current
    if (video.currentTime) {
      const percent = (video.currentTime / video.duration) * 100
      setProgressActivity(percent)
    }
  }

  const handleMouseOver = () => {
    setMouseOver(!isMouseOver)
  }

  const handleFullScreen = () => {
    const player = playerRef.current
    if (!document.fullscreenElement) {
      player.requestFullscreen().catch(err => {
        throw new Error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        )
      })
    } else {
      document.exitFullscreen()
    }
  }

  return (
    <Player ref={playerRef}>
      {!isPlaying && (
        <StartPlay
          aria-label={t.player.play}
          role="button"
          onClick={handlePlay}
        >
          <FaPlay size="1.3em" color="#fff" />
        </StartPlay>
      )}

      <Video
        ref={videoRef}
        loop
        poster={posterSrc}
        onClick={handlePlay}
        onTimeUpdate={handleProgress}
        crossOrigin="anonymous"
      >
        <source src={src} type="video/mp4" preload="metadata" />
      </Video>

      <Controls
        role="button"
        playing={isPlaying}
        over={isMouseOver}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
      >
        <Progress
          ref={progressRef}
          role="button"
          onClick={ev => handleScrub(ev)}
        >
          <ProgressBar style={{ flexBasis: `${progressActivity}%` }} />
        </Progress>

        <FlexWrap>
          <Title>{title}</Title>

          <ControlButton
            aria-label={isPlaying ? t.player.pause : t.player.play}
            type="button"
            onClick={handlePlay}
          >
            {isPlaying ? <FaPause /> : <FaPlay />}
          </ControlButton>

          <ControlButton
            aria-label={t.player.fullscreen}
            type="button"
            onClick={handleFullScreen}
          >
            <FaArrowsAlt />
          </ControlButton>
        </FlexWrap>
      </Controls>
    </Player>
  )
}

VideoPlayer.propTypes = {
  locale: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.shape({
    poster: PropTypes.shape({
      src: PropTypes.string
    })
  })
}

VideoPlayer.defaultProps = {
  poster: {}
}

export default VideoPlayer
