import { theme, styled } from '../../shared/theme'

export const Paragraph = styled.div`
  position: relative;
  padding-bottom: 2em;
  column-count: 1;
  column-fill: auto;

  @media ${theme.mq.laptop} {
    column-count: 2;
    column-gap: 1em;
  }

  @media ${theme.mq.desktop} {
    column-count: 2;
    column-gap: 2em;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    column-count: 2;
    column-gap: 4em;
  }

  /*
   * Custom content styles
   * Allowed tags: 'em', 'strong', 'a', 'ul', 'ol', 'li', 'p'
   */

  > p:first-of-type {
    margin-top: 0;
  }

  h2,
  h3,
  h4,
  h5,
  strong {
    column-span: all;
  }

  > p > strong {
    font-size: 110%;
  }

  a {
    color: ${theme.colors.gray};
    text-decoration: underline;

    &:active,
    &:hover,
    &:focus {
      color: ${theme.colors.primary};
      text-decoration: none;
    }
  }

  ol li {
    padding-bottom: 10px;
  }
`
