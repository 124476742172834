import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Thumb from '../thumb'
import useContentfulPage from '../../hooks/use-contentful-page'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { buildPath } from '../../shared/helpers'
import {
  Columns,
  DateColumn,
  Column,
  Published,
  Time,
  Year,
  Figure,
  Content,
  Title,
  Excerpt,
  ReadMore
} from './styles'

const References = ({ slug, locale }) => {
  const { nodes: pages } = useContentfulPage()
  const { nodes: settings } = useContentfulSettings()
  const { page } = pages.find(node => node.slug === slug)
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations

  // Can show no results ..
  if (!page) return ''

  return (
    <>
      {page.map(entry => {
        const thumbId = entry.slides[0].media.contentful_id
        const link = buildPath(entry.node_locale, entry)

        return (
          <Columns key={entry.contentful_id}>
            <DateColumn>
              <Published>
                <Time dateTime={entry.updatedAt}>
                  {entry.shortDate}
                  <Year>{entry.year}</Year>
                </Time>
                <Figure>
                  <Thumb id={thumbId} />
                </Figure>
              </Published>
            </DateColumn>
            <Column>
              <Content>
                <Title>
                  <Link to={link} rel="noopener">
                    {entry.title}
                  </Link>
                </Title>
                {entry.description && (
                  <Excerpt>
                    {entry.description.childMarkdownRemark.excerpt}
                  </Excerpt>
                )}
                <ReadMore>
                  <Link to={link} rel="noopener">
                    {t.readMore}
                  </Link>
                </ReadMore>
              </Content>
            </Column>
          </Columns>
        )
      })}
    </>
  )
}

References.propTypes = {
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired
}

export default References
