import { theme, styled } from '../../shared/theme'

export const Container = styled.div`
  background-color: ${theme.colors.black};
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: -100;
  overflow: hidden;
`

export const Video = styled.video`
  display: block;
  position: relative;
  width: auto;
  min-width: 100%;
  height: auto;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
`
