import React from 'react'
import LocaleConsumer from '../../context/locale'
import useContentfulMenu from '../../hooks/use-contentful-menu'
import LogoVertical from '../logo-vertical'
import { buildMenu } from '../../shared/helpers'
import {
  Footer as StyledFooter,
  Container,
  Columns,
  ColumnLeft,
  Ul,
  Li,
  Link,
  A,
  ColumnRight,
  LogoContainer,
  LanguageChoices,
  Span,
  Button,
  Copyright
} from './styles'

const getMenus = (locale, nodes) => {
  return ['services', 'sveahus', 'follow-us'].map(name => {
    return buildMenu(
      nodes.find(
        ({ objectId, node_locale }) =>
          objectId === name && node_locale === locale
      )
    )
  })
}

const Footer = () => {
  const { nodes } = useContentfulMenu()

  return (
    <LocaleConsumer>
      {({ locale, changeLocale }) => {
        const menus = getMenus(locale, nodes)

        return (
          <StyledFooter>
            <Container>
              <Columns>
                <ColumnLeft>
                  {menus.map(menu => {
                    return (
                      <Ul key={menu.key} data-name={menu.title}>
                        {menu.childs.map(child => {
                          return child.path ? (
                            <Li key={child.key}>
                              <Link to={child.path} activeClassName="is-active">
                                {child.title}
                              </Link>
                            </Li>
                          ) : (
                            <Li key={child.key}>
                              <A
                                href={child.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {child.title}
                              </A>
                            </Li>
                          )
                        })}
                      </Ul>
                    )
                  })}
                </ColumnLeft>
                <ColumnRight>
                  <LogoContainer>
                    <LogoVertical />
                  </LogoContainer>
                  <LanguageChoices>
                    <Button
                      aria-label="English"
                      type="button"
                      onClick={() => changeLocale('en')}
                      className={locale === 'en' ? 'is-active' : ''}
                    >
                      English
                    </Button>
                    <Span />
                    <Button
                      aria-label="Svenska"
                      type="button"
                      onClick={() => changeLocale('sv')}
                      className={locale === 'sv' ? 'is-active' : ''}
                    >
                      Svenska
                    </Button>
                  </LanguageChoices>
                  <Copyright>
                    <span>© Sveahus</span>
                    {new Date().getFullYear()}
                  </Copyright>
                </ColumnRight>
              </Columns>
            </Container>
          </StyledFooter>
        )
      }}
    </LocaleConsumer>
  )
}

export default Footer
