import React from 'react'
import { css } from '@emotion/core'

const svg = css`
  width: 44px;
  height: auto;
`

const MapMarker = () => (
  <svg viewBox="0 0 154 227" css={svg}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fillOpacity="0.2"
        fill="#FFFFFF"
        fillRule="nonzero"
        d="M86.1625985,215.610163 L78.095531,227 L69.9021668,215.700535 C23.3668132,151.523649 0,106.576474 0,78.9389732 C0,35.3070151 34.9567561,0 78,0 C121.332865,0 154,34.7011193 154,78.9389732 C154,106.704309 131.220435,151.993317 86.1625985,215.610163 Z"
      />
      <path
        fill="#FFFFFF"
        d="M134,78.9389732 C134,45.4742266 110.004385,19.9845502 78,19.9845502 C46.0508315,19.9845502 20,46.2964053 20,78.9389732 C20,99.4013684 39.3318665,137.898926 77.9012071,192.523471 C115.217023,138.340683 134,99.6493545 134,78.9389732 Z"
      />
      <g transform="translate(40.000000, 37.000000)" fillRule="nonzero">
        <polyline
          fillOpacity="0.2"
          fill="#4A4A4A"
          points="0 64.9056604 37 86 74 64.9056604 37 43 0 64.9056604"
        />
        <polyline
          fillOpacity="0.5"
          fill="#226290"
          points="0 42.0943396 37 64 74 42.0943396 37 21 0 42.0943396"
        />
        <polyline
          fillOpacity="0.5"
          fill="#FDDC75"
          points="37 43 74 21.9056604 37 0 0 21.0943396 37 43"
        />
      </g>
    </g>
  </svg>
)

export default () => (
  <div css={svg}>
    <MapMarker />
  </div>
)
