/* formik is built for typescript so hard to set valid prop types */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { FaEnvelopeOpen, FaMobileAlt, FaMapMarkerAlt } from 'react-icons/fa'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import { interpolate } from '../../shared/helpers'
import GoogleMap from '../google-map'
import {
  Section,
  Columns,
  Column,
  Title,
  Paragraph,
  Ul,
  Li,
  A,
  Notification,
  Submit,
  FormContainer,
  FormTitle,
  FieldGroup,
  Label,
  Input,
  Textarea,
  Hint
} from './styles'

const TextField = ({ label, field, form: { touched, errors }, ...props }) => {
  return (
    <FieldGroup>
      <Label htmlFor={field.name}>{label}</Label>
      <Input {...field} {...props} />
      {touched[field.name] && errors[field.name] && (
        <Hint>{errors[field.name]}</Hint>
      )}
    </FieldGroup>
  )
}

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired
}

const TextArea = ({ label, field, form: { touched, errors }, ...props }) => {
  return (
    <FieldGroup>
      <Label htmlFor={field.name}>{label}</Label>
      <Textarea {...field} {...props} />
      {touched[field.name] && errors[field.name] && (
        <Hint>{errors[field.name]}</Hint>
      )}
    </FieldGroup>
  )
}

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.any.isRequired,
  form: PropTypes.any.isRequired
}

const Contact = ({ locale }) => {
  const { nodes: settings } = useContentfulSettings()
  const setting = settings.find(({ node_locale }) => node_locale === locale)
  const t = setting.translations

  const [status, setStatus] = useState('')

  const ContactSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t.contact.validation.short)
      .required(t.contact.validation.required),
    email: Yup.string()
      .email(t.contact.validation.email)
      .required(t.contact.validation.required),
    message: Yup.string()
      .min(2, t.contact.validation.short)
      .required(t.contact.validation.required)
  })

  const parsePhoneNumber = number => number.toString().replace(/\(0\)|\s+/g, '')

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      ...values,
      subject: interpolate(t.contact.form.subject, {
        name: values.name
      }),
      internal: {
        company: setting.company,
        address: setting.address,
        email: setting.email,
        contact: t.contact.form.title,
        copyright: interpolate(t.copyright, {
          year: new Date().getFullYear()
        })
      }
    }

    fetch('/.netlify/functions/contact', {
      method: 'post',
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(() => {
        setStatus(t.contact.form.success)
        resetForm()
        setTimeout(() => setStatus(''), 6000)
      })
      .catch(error => setStatus(error))
  }

  return (
    <Section>
      <Columns>
        <Column>
          <Title>{t.contact.title}</Title>
          <Paragraph>{t.contact.description}</Paragraph>
          <Ul>
            <Li>
              <FaEnvelopeOpen />
              <A
                href={`mailto:${setting.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {setting.email}
              </A>
            </Li>
            <Li>
              <FaMobileAlt />
              <A
                href={`tel:${parsePhoneNumber(setting.phone)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {setting.phone}
              </A>
            </Li>
            <Li>
              <FaMapMarkerAlt />
              <A
                href={`//maps.google.com/?q=${setting.address}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {setting.address}
              </A>
            </Li>
          </Ul>
        </Column>

        <Column>
          <FormContainer>
            <FormTitle>{t.contact.form.title}</FormTitle>
            {status && <Notification>{status}</Notification>}
            <Formik
              initialValues={{
                name: '',
                email: '',
                message: ''
              }}
              validationSchema={ContactSchema}
              onSubmit={handleSubmit}
            >
              {({ errors }) => (
                <Form>
                  <Field
                    name="name"
                    type="text"
                    label={t.contact.form.name}
                    component={TextField}
                  />
                  <Field
                    name="email"
                    type="email"
                    label={t.contact.form.email}
                    component={TextField}
                  />
                  <Field
                    name="message"
                    label={t.contact.form.message}
                    component={TextArea}
                  />
                  <FieldGroup>
                    <Submit
                      aria-label="Submit"
                      type="submit"
                      disabled={Object.keys(errors).length > 0}
                    >
                      {t.contact.form.send}
                    </Submit>
                  </FieldGroup>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </Column>
      </Columns>
      <GoogleMap locale={locale} />
    </Section>
  )
}

Contact.propTypes = {
  locale: PropTypes.string.isRequired
}

export default Contact
