import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import useContentfulSettings from '../../hooks/use-contentful-settings'
import MapMarker from './map-marker'
import { Section, MapWrapper } from './styles'
import { mapStyles } from './map-styles'

const GoogleMap = ({ locale }) => {
  const { nodes: settings } = useContentfulSettings()
  const setting = settings.find(({ node_locale }) => node_locale === locale)

  const urlProps = {
    key: setting.googleMapKey,
    language: locale,
    region: 'SE'
  }

  const defaultProps = {
    zoom: 15,
    disableDefaultUI: true,
    styles: mapStyles,
    center: {
      lat: setting.location.lat,
      lng: setting.location.lon
    }
  }

  // Change center offset to 28% of window width.
  const handleApiLoaded = ({ map }) =>
    map.panBy(document.body.clientWidth * 0.28, 0)

  return (
    <Section>
      <MapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={urlProps}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={defaultProps}
          onGoogleApiLoaded={handleApiLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          <MapMarker lat={setting.location.lat} lng={setting.location.lon} />
        </GoogleMapReact>
      </MapWrapper>
    </Section>
  )
}

GoogleMap.propTypes = {
  locale: PropTypes.string.isRequired
}

export default GoogleMap
