import React from 'react'
import PropTypes from 'prop-types'
import { FormattedDate } from 'react-intl'
import { Columns, Column, Time, Excerpt, P } from './styles'

const Authored = ({ updatedAt, excerpt }) => (
  <Columns>
    <Column>
      <Time dateTime={updatedAt}>
        <FormattedDate value={new Date(updatedAt)} />
      </Time>
    </Column>
    <Excerpt>
      <P>{excerpt}</P>
    </Excerpt>
  </Columns>
)

Authored.propTypes = {
  updatedAt: PropTypes.string.isRequired,
  excerpt: PropTypes.string
}

Authored.defaultProps = {
  excerpt: ''
}

export default Authored
