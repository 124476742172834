import Img from 'gatsby-image'
import { theme, styled } from '../../shared/theme'

export const Section = styled.section`
  position: relative;
  background-color: ${theme.colors.black};
  padding: 1em 0;
  z-index: 2;

  @media ${theme.mq.tablet} {
    padding: 1em;
  }
`

export const Figure = styled.figure`
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex: auto;
  min-width: ${props => props.minWidth}px;
`

export const Image = styled(Img)`
  width: 100%;
  height: auto;
  z-index: 2;
`
