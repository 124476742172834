import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import useContentfulSection from '../../hooks/use-contentful-section'
import useContentfulAsset from '../../hooks/use-contentful-asset'
import useMedia from '../../hooks/use-media'
import { theme } from '../../shared/theme'
import { buildPath } from '../../shared/helpers'
import Masonary from '../masonary'
import VideoPlayer from '../video-player'
import VideoYouTube from '../video-youtube'
import {
  Container,
  Title,
  Excerpt,
  Figure,
  Image as StyledImage,
  Caption,
  CaptionTitle,
  CaptionExcerpt
} from './styles'

const Image = ({ locale, title, description, media, page }) => {
  return (
    <>
      <StyledImage fluid={media.fluid} title={title} />
      {title && (
        <Caption>
          <CaptionTitle>
            {page ? <Link to={buildPath(locale, page)}>{title}</Link> : title}
          </CaptionTitle>
          {description && <CaptionExcerpt>{description}</CaptionExcerpt>}
        </Caption>
      )}
    </>
  )
}

Image.propTypes = {
  locale: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  media: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string
    })
  }).isRequired,
  page: PropTypes.shape({
    slug: PropTypes.string,
    ancestor: PropTypes.shape({
      slug: PropTypes.string
    })
  })
}

Image.defaultProps = {
  description: '',
  page: null
}

const Section = ({ locale, section }) => {
  const { nodes: sections } = useContentfulSection()
  const { nodes: assets } = useContentfulAsset()

  const result = sections.find(
    ({ contentful_id, node_locale }) =>
      contentful_id === section.contentful_id && node_locale === locale
  )

  const minWidth = useMedia(
    [theme.fullhd, theme.mq.desktop, theme.mq.tablet],
    [800, 600, 500],
    300
  )

  // Get media from assets because localized media in contentful is empty.
  const references = [
    ...result.references.map(ref => {
      return {
        ...ref,
        locale,
        media: {
          ...(ref.youTubeLink
            ? {}
            : assets.find(
                asset => asset.contentful_id === ref.media.contentful_id
              ))
        },
        poster: {
          ...(ref.poster
            ? assets.find(
                asset => asset.contentful_id === ref.poster.contentful_id
              )
            : {})
        }
      }
    })
  ]

  return (
    <Container>
      <Title>{result.title}</Title>
      <Excerpt
        dangerouslySetInnerHTML={{
          __html: result.description.childMarkdownRemark.html
        }}
      />
      <Masonary gap="1em" minWidth={minWidth}>
        {references.map(ref => (
          <Figure key={ref.contentful_id} minWidth={minWidth}>
            {ref.youTubeLink ? (
              <VideoYouTube url={ref.youTubeLink} />
            ) : ref.media.file.contentType.startsWith('video') ? (
              <VideoPlayer
                locale={locale}
                src={ref.media.file.url}
                poster={ref.poster}
                title={ref.title}
              />
            ) : (
              <Image {...ref} />
            )}
          </Figure>
        ))}
      </Masonary>
    </Container>
  )
}

Section.propTypes = {
  locale: PropTypes.string.isRequired,
  section: PropTypes.shape({
    contentful_id: PropTypes.string,
    description: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        excerpt: PropTypes.string,
        html: PropTypes.string
      })
    }),
    references: PropTypes.arrayOf(
      PropTypes.shape({
        contentful_id: PropTypes.string,
        title: PropTypes.string,
        media: PropTypes.shape({
          contentful_id: PropTypes.string
        }),
        poster: PropTypes.shape({
          contentful_id: PropTypes.string
        })
      })
    )
  }).isRequired
}

export default Section
