import safeGet from 'lodash.get'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Thumb = ({ id, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(filter: { file: { contentType: { ne: null } } }) {
        nodes {
          contentful_id
          fixed(
            width: 100
            height: 100
            quality: 100
            cropFocus: CENTER
            resizingBehavior: THUMB
          ) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
      data.allContentfulAsset.nodes.find(
        ({ contentful_id }) => contentful_id === id
      ),
    [data, id]
  )

  const fixed = safeGet(match, 'fixed')
  return fixed ? <Img fixed={fixed} Tag="div" {...props} /> : null
}

Thumb.propTypes = {
  id: PropTypes.string.isRequired
}

export default Thumb
