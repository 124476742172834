import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetaData = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteUrl
            title
            description
            author {
              name
            }
            organization {
              name
              logo
            }
            social {
              twitter
              pinterestDomainVerify
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetaData
