import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const SchemaOrg = memo(
  ({
    isIndex,
    siteUrl,
    title,
    defaultTitle,
    description,
    author,
    organization,
    datePublished
  }) => {
    const baseSchema = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        '@id': siteUrl,
        url: siteUrl,
        name: title,
        description,
        alternateName: defaultTitle
      }
    ]

    const schema = isIndex
      ? [
          ...baseSchema,
          {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': siteUrl,
                  name: title,
                  image: organization.logo
                }
              }
            ]
          },
          {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            url: siteUrl,
            name: title,
            alternateName: defaultTitle,
            headline: title,
            image: {
              '@type': 'ImageObject',
              url: organization.logo
            },
            description,
            author: {
              '@type': 'Person',
              name: author.name
            },
            publisher: {
              '@type': 'Organization',
              url: siteUrl,
              logo: organization.logo,
              name: organization.name
            },
            mainEntityOfPage: {
              '@type': 'WebSite',
              '@id': siteUrl
            },
            datePublished
          }
        ]
      : baseSchema

    return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    )
  }
)

SchemaOrg.propTypes = {
  isIndex: PropTypes.bool.isRequired,
  siteUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string
  }).isRequired,
  datePublished: PropTypes.string
}

SchemaOrg.defaultProps = {
  datePublished: ''
}

export default SchemaOrg
