import { rgba } from 'polished'
import { theme, styled } from '../../shared/theme'

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
  background-color: ${rgba(theme.colors.black, 0.99)};
  min-height: 30px;
  max-width: 400px;
  z-index: 99999;
  color: ${theme.colors.grayLight};
  font-weight: 300;
  padding: 0.5em 1em;
  text-align: left;
  margin: 0 auto;
  box-shadow: 0 2px 2px ${rgba(theme.colors.black, 0.3)};

  display: ${props => (props.closed === true ? 'none' : 'flex')};

  @media ${theme.mq.mobileL} {
    left: unset;
    right: 2em;
    bottom: 2em;
  }
`

export const Paragraph = styled.p`
  display: block;
  padding: 0 2em 0 0;
  font-size: 14px;

  @media ${theme.mq.fullhd} {
    font-size: 16px;
  }

  a {
    padding-left: 0.3em;
    color: ${theme.colors.white};

    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.primary};
    }
  }
`

export const Close = styled.button`
  display: block;
  position: absolute;
  right: 1em;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  opacity: 0.3;
  background: none;
  padding: 0;
  margin: auto;
  border: 0;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    top: -1px;
    bottom: 0;
    left: 8px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: ${theme.colors.white};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`
