import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as Fa from 'react-icons/fa'
import Logo from '../logo'
import ContentConsumer from '../../context/content'
import LocaleConsumer from '../../context/locale'
import useContentfulMenu from '../../hooks/use-contentful-menu'
import { getChild } from '../../shared/helpers'
import {
  Nav,
  LocaleChoices,
  Button,
  Brand,
  Bars,
  Bar,
  Menu,
  MenuList,
  Li,
  A
} from './styles'

const buildMenu = node => {
  return [...node.childs.map(child => getChild(node.node_locale, child))]
}

const getMenu = (locale, nodes, name) => {
  return buildMenu(
    nodes.find(
      ({ objectId, node_locale }) => objectId === name && node_locale === locale
    )
  )
}

const NavLink = ({ path, title }) => (
  <Link to={path} activeClassName="is-active">
    {title}
  </Link>
)

NavLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

const SocialLink = ({ url, icon }) => {
  const Icon = Fa[icon]
  return (
    <A href={url} target="_blank" rel="noopener noreferrer" color={icon}>
      <Icon />
    </A>
  )
}

SocialLink.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}

const SubNav = ({ locale, link }) => {
  const navLink = [locale === 'en' ? null : locale, link.page.slug]
    .filter(Boolean)
    .join('/')

  return (
    <>
      <Link to={`/${navLink}`} activeClassName="is-active" partiallyActive>
        {link.title}
      </Link>
      <ul>
        {link.childs.map(({ key, path, title }) => (
          <li key={key}>
            <NavLink path={path} title={title} activeClassName="is-active" />
          </li>
        ))}
      </ul>
    </>
  )
}

SubNav.propTypes = {
  locale: PropTypes.string.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    page: PropTypes.shape({
      slug: PropTypes.string
    }),
    childs: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        path: PropTypes.string,
        title: PropTypes.string
      })
    )
  }).isRequired
}

const Navbar = () => {
  const { nodes } = useContentfulMenu()

  return (
    <ContentConsumer>
      {({ state, set }) => (
        <LocaleConsumer>
          {({ locale, changeLocale }) => {
            const menu = getMenu(locale, nodes, 'navbar')

            return (
              <Nav aria-label="Navigation" role="navigation">
                <LocaleChoices>
                  <Button
                    aria-label="English"
                    type="button"
                    className={locale === 'en' ? 'is-active' : ''}
                    onClick={() => changeLocale('en')}
                  >
                    English
                  </Button>
                  <Button
                    aria-label="Svenska"
                    type="button"
                    className={locale === 'sv' ? 'is-active' : ''}
                    onClick={() => changeLocale('sv')}
                  >
                    Svenska
                  </Button>
                </LocaleChoices>
                <Bars
                  aria-label="Menu"
                  type="button"
                  onClick={() => set({ isSidebarOpen: !state.isSidebarOpen })}
                >
                  <Bar open={state.isSidebarOpen} />
                </Bars>
                <Brand>
                  <Logo />
                </Brand>
                <Menu>
                  <MenuList>
                    {menu.map(link => (
                      <Li key={link.key}>
                        {link.path && (
                          <NavLink path={link.path} title={link.title} />
                        )}
                        {link.url && (
                          <SocialLink url={link.url} icon={link.icon} />
                        )}
                        {link.childs && <SubNav locale={locale} link={link} />}
                      </Li>
                    ))}
                  </MenuList>
                </Menu>
              </Nav>
            )
          }}
        </LocaleConsumer>
      )}
    </ContentConsumer>
  )
}

export default Navbar
