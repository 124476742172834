import Img from 'gatsby-image'
import { rgba } from 'polished'
import { theme, styled } from '../../shared/theme'

export const Container = styled.section`
  position: relative;
  background-color: ${theme.colors.black};
  z-index: 2;
  padding: 50px 0 20px;

  @media ${theme.mq.tablet} {
    padding: 50px 1em 20px;
  }
`

export const Title = styled.h2`
  font-weight: 500;
  text-align: center;
  color: ${theme.colors.white};
  text-transform: uppercase;
  line-height: 1;
  padding: 0 0 20px;
  font-size: 20px;

  @media ${theme.mq.mobileL} {
    font-size: 24px;
  }

  @media ${theme.mq.tablet} {
    font-size: 32px;
  }

  @media ${theme.mq.laptop} {
    font-size: 38px;
  }

  @media ${theme.mq.desktop} {
    font-size: 42px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 64px;
  }
`

export const Excerpt = styled.p`
  font-weight: 500;
  text-align: center;
  color: ${theme.colors.white};
  margin: auto;
  padding-bottom: 50px;
  line-height: 1.5;
  font-size: 16px;
  max-width: 100%;

  @media ${theme.mq.laptop} {
    font-size: 16px;
    max-width: 50vw;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    max-width: 40vw;
  }
`

export const Figure = styled.figure`
  position: relative;
  margin: 0;
  display: flex;
  flex: auto;
  width: 100%;
  height: 100%;
  min-width: ${props => props.minWidth}px;
`

export const Caption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${rgba(theme.colors.black, 0.3)};
  text-align: left;
  z-index: 3;
  max-height: 200px;
  padding: 20px 20px 10px;
`

export const CaptionTitle = styled.h3`
  position: relative;
  color: ${theme.colors.white};
  width: inherit;
  font-weight: 500;
  text-transform: uppercase;

  /* soft ellipsis */
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 2px;
  line-height: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    background-color: ${theme.colors.primary};
    width: 15px;
    height: 4px;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
      color: ${theme.colors.primary};
    }
  }
`

export const CaptionExcerpt = styled.p`
  color: ${theme.colors.white};
  display: block;
  overflow: auto;
  max-height: calc(14px * 4);
  line-height: 1;
  font-size: 14px;

  @media ${theme.mq.fullhd} {
    max-height: calc(14px * 4);
    font-size: 24px;
  }
`

export const Image = styled(Img)`
  width: 100%;
  height: auto;
  z-index: 2;
`
