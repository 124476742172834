import { graphql, useStaticQuery } from 'gatsby'

const useContentfulPage = () => {
  const { allContentfulPage } = useStaticQuery(
    graphql`
      query ContentfulPages {
        allContentfulPage {
          nodes {
            contentful_id
            node_locale
            slug
            title
            page {
              contentful_id
              node_locale
              title
              slug
              updatedAt
              shortDate: updatedAt(formatString: "D MMM")
              year: updatedAt(formatString: "YYYY")
              ancestor {
                slug
              }
              description {
                childMarkdownRemark {
                  excerpt
                  html
                }
              }
              content {
                childMarkdownRemark {
                  excerpt
                  html
                }
              }
              slides {
                media {
                  contentful_id
                }
              }
              gallery {
                contentful_id
              }
            }
          }
        }
      }
    `
  )
  return allContentfulPage
}

export default useContentfulPage
