export const interpolate = (str, obj) =>
  str.replace(/{\s?([^{}\s]*)\s?}/g, (_, v) => obj[v])

export const startCase = text => {
  if (!text) return ''

  return text
    .replace('_', ' ')
    .replace('-', ' ')
    .replace(
      /([^\W_]+[^\s-]*) */g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
}

export const buildPath = (locale, { slug, ancestor }) => {
  if (!slug) return ''

  const slugs = new Set([
    locale === 'en' ? null : locale,
    ancestor ? ancestor.slug : null,
    slug
  ])

  const path = Array.from(slugs)
    .filter(Boolean)
    .join('/')

  return path === 'en' ? '/' : `/${path}`
}

export const getChild = (locale, child) => {
  return {
    ...{
      key: child.contentful_id,
      path: buildPath(locale, child),
      url: child.url,
      icon: child.icon,
      title: child.title,
      page: child.page,
      childs: child.childs && [...child.childs.map(c => getChild(locale, c))]
    }
  }
}

export const buildMenu = node => {
  return {
    ...{
      key: node.contentful_id,
      locale: node.node_locale,
      title: node.title,
      childs: node.childs && [
        ...node.childs.map(child => getChild(node.node_locale, child))
      ]
    }
  }
}

export const getLang = locale => {
  const langs = { en: 'en_US', sv: 'sv_SE' }
  return Object.values(langs)
    .filter(o => o.includes(locale))
    .toString()
}

export const transparentPixelSrc =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
