import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { lighten, rgba } from 'polished'
import { theme, styled } from '../../shared/theme'

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding-top: 20px;
`

export const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  z-index: 3;
  width: 100%;
  margin: 0 auto;
  max-width: ${theme.breakpoints[3]}px;
  padding: 0 1em;

  @media ${theme.mq.laptop} {
    flex-flow: row;
  }

  @media ${theme.mq.desktop} {
    padding: 0;
  }

  @media ${theme.mq.fullhd} {
    max-width: ${theme.breakpoints[4]}px;
  }
`

export const Column = styled.div`
  position: relative;

  @media ${theme.mq.laptop} {
    width: 50%;
  }
`

export const Title = styled.h1`
  font-weight: ${theme.fontWeight.bold};
  font-size: 20px;
  line-height: 1;

  @media ${theme.mq.mobileL} {
    font-size: 24px;
  }

  @media ${theme.mq.tablet} {
    font-size: 32px;
    letter-spacing: 2px;
  }

  @media ${theme.mq.laptop} {
    padding-top: 12px;
    font-size: 38px;
  }

  @media ${theme.mq.desktop} {
    font-size: 42px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 64px;
  }
`

export const Paragraph = styled.p`
  font-size: 16px;

  @media ${theme.mq.fullhd} {
    font-size: 24px;
  }
`

export const Ul = styled.ul`
  margin: 0;
  padding: 0;
  padding-bottom: 2em;
`

export const Li = styled.li`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 16px;
  line-height: 30px;

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    line-height: 40px;
  }
`

export const A = styled(OutboundLink)`
  padding-left: 10px;
  color: ${theme.colors.text};
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    color: ${theme.colors.primary};
  }
`

export const FormContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  position: relative;
  padding: 0 0 2em;
  left: 0;

  @media ${theme.mq.laptop} {
    position: absolute;
    left: 0;
    padding: 5px 50px 50px;
    box-shadow: 0 8px 10px 6px ${rgba(theme.colors.black, 0.1)};
  }
`

export const FormTitle = styled.h2`
  font-weight: ${theme.fontWeight.bold};
  font-size: 20px;
  line-height: 1;

  @media ${theme.mq.mobileL} {
    font-size: 24px;
  }

  @media ${theme.mq.tablet} {
    font-size: 32px;
    letter-spacing: 2px;
  }

  @media ${theme.mq.laptop} {
    font-size: 38px;
  }

  @media ${theme.mq.desktop} {
    font-size: 42px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 64px;
  }
`

export const Label = styled.label`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.black};
  text-transform: uppercase;
  font-size: 14px;

  @media ${theme.mq.fullhd} {
    font-size: 16px;
  }
`

export const FieldGroup = styled.div`
  position: relative;
  margin-bottom: 1em;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.has-error {
    + input,
    + textarea {
      &,
      &:active,
      &:hover,
      &:focus {
        border-color: ${theme.colors.red};
      }
    }

    + span {
      color: ${theme.colors.red};
    }
  }
`

export const Input = styled.input`
  border-radius: 4px;
  border: 2px solid ${theme.colors.light};
  box-shadow: none;
  transition: border-color 1s;
  background-color: transparent;
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  height: 2.25em;
  line-height: 1.5;
  padding: 0.3em 0.6em;
  font-size: 16px;

  @media ${theme.mq.fullhd} {
    font-size: 24px;
  }

  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: ${theme.colors.primary};
  }
`

export const Textarea = styled.textarea`
  border-radius: 4px;
  border: 2px solid ${theme.colors.light};
  box-shadow: none;
  transition: border-color 1s;
  background-color: transparent;
  resize: vertical;
  width: 100%;
  max-height: 600px;
  min-height: 120px;
  line-height: 1.5;
  padding: 0.3em 0.6em;
  font-size: 16px;

  @media ${theme.mq.fullhd} {
    font-size: 24px;
  }

  &:active,
  &:hover,
  &:focus {
    box-shadow: none;
    border-color: ${theme.colors.primary};
  }
`

export const Hint = styled.span`
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${theme.colors.grayLight};
  font-size: 11px;

  @media ${theme.mq.fullhd} {
    font-size: 14px;
  }
`

export const Notification = styled.div`
  border-radius: 4px;
  background-color: ${lighten(0.2, theme.colors.primary)};
  border: 2px solid ${rgba(theme.colors.primary, 0.2)};
  padding: 0.5em;
  margin-bottom: 1em;
  font-size: 14px;

  @media ${theme.mq.fullhd} {
    font-size: 16px;
  }
`

export const Submit = styled.button`
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  min-width: 150px;
  min-height: 50px;
  transition: border 0.5s;

  color: ${theme.colors.text};
  border: 2px solid ${theme.colors.light};
  background-color: ${theme.colors.white};

  &:hover,
  &:focus,
  &:active {
    border-color: ${theme.colors.primary};
  }

  &:disabled {
    color: ${theme.colors.light};
    border-color: ${theme.colors.light};
    user-select: none;
    pointer-events: none;
  }

  &:focus {
    box-shadow: none;
  }
`
