import { graphql, useStaticQuery } from 'gatsby'

const useContentfulSettings = () => {
  const { allContentfulSettings } = useStaticQuery(
    graphql`
      query ContentfulSettings {
        allContentfulSettings {
          nodes {
            node_locale
            address
            company
            email
            phone
            googleMapKey
            location {
              lat
              lon
            }
            translations {
              slogan
              copyright
              description
              navLabel
              search
              close
              menu
              readAll
              readMore
              player {
                play
                pause
                fullscreen
                soundOn
                soundOff
              }
              notFound {
                title
                description
              }
              contact {
                title
                description
                form {
                  title
                  name
                  email
                  subject
                  message
                  send
                  success
                }
                validation {
                  required
                  short
                  email
                }
              }
              consent {
                uses
                terms
                link
              }
            }
          }
        }
      }
    `
  )
  return allContentfulSettings
}

export default useContentfulSettings
