import { theme, styled } from '../../shared/theme'

export const Columns = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0 0 2em;

  @media ${theme.mq.tablet} {
    flex-flow: row;
    padding: 0 0 2em;
  }

  @media ${theme.mq.desktop} {
    padding: 0 0 3em;
  }
`

export const DateColumn = styled.div`
  position: relative;
  flex: none;
  width: 16.6666%;
  display: none;

  @media ${theme.mq.tablet} {
    display: block;
  }
`

export const Column = styled.div`
  position: relative;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
`

export const Published = styled.div`
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  border-radius: 4px;
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};

  @media ${theme.mq.desktop} {
    font-size: 16px;
  }
`

export const Time = styled.time`
  display: block;
  padding: 10px 15px;
  margin: auto;
`

export const Year = styled.span`
  @media ${theme.mq.tablet} {
    display: block;
  }
`

export const Figure = styled.figure`
  overflow: hidden;
  margin: auto;
  width: 100px;
  height: 70px;
  border-radius: 0 0 4px 4px;

  &:not(:first-of-type) {
    margin-top: 0; /* reset */
  }

  img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
`

export const Content = styled.div`
  padding-left: 1em;

  @media ${theme.mq.desktop} {
    padding-left: 0;
  }
`

export const Title = styled.h3`
  margin: 0;
  font-weight: ${theme.fontWeight.bold};
  font-size: 18px;
  line-height: 1;

  @media ${theme.mq.tablet} {
    font-size: 20px;
  }

  @media ${theme.mq.desktop} {
    font-size: 24px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 32px;
  }

  a {
    display: block;
    height: 100%;
    color: ${theme.colors.text};
    transition: color 0.5s;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.primary};
    }
  }
`

export const Excerpt = styled.p`
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 21px;

  @media ${theme.mq.desktop} {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 27px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    line-height: 32px;
  }
`

export const ReadMore = styled.p`
  font-weight: ${theme.fontWeight.bold};
  font-size: 14px;
  letter-spacing: 1.2px;

  @media ${theme.mq.desktop} {
    font-size: 16px;
    letter-spacing: 2px;
  }

  @media ${theme.mq.fullhd} {
    font-size: 24px;
    line-height: 32px;
  }

  a {
    position: relative;
    width: 50%;
    margin: auto;
    text-align: right;
    color: ${theme.colors.text};
    transition: color 0.5s;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.primary};
    }
  }
`
