import { Link as GatsbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { theme, styled } from '../../shared/theme'

export const Footer = styled.footer`
  position: relative;
  background-color: ${theme.colors.white};
  padding: 1em 1em;
  z-index: 2;

  @media ${theme.mq.desktop} {
    min-height: 450px;
    padding: 0;
  }
`

export const Container = styled.div`
  min-height: inherit;
  display: flex;
  flex-flow: column;
  margin: auto;
  max-width: ${theme.breakpoints[3]}px;

  @media ${theme.mq.fullhd} {
    max-width: ${theme.breakpoints[4]}px;
  }
`

export const Columns = styled.div`
  margin: 0;
  min-height: inherit;
  display: flex;
  flex-flow: row;
`

export const Column = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 0 1em;

  @media ${theme.mq.tablet} {
    padding: 0;
  }

  @media ${theme.mq.laptop} {
    padding: 50px 0;
  }
`

export const ColumnLeft = styled(Column)`
  display: none;

  @media ${theme.mq.laptop} {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    flex: none;
    width: calc(100% / 3 * 2);
  }
`

export const Ul = styled.ul`
  position: relative;
  min-width: calc(100% / 3);
  padding: 0 0 4px 25px;
  list-style: none;
  margin: 0;

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    content: '';
    top: 0;
    left: 9px;
    right: 5px;
    width: 10px;
    height: 4px;
    background-color: ${theme.colors.primary};
  }

  &::after {
    content: attr(data-name);
    top: 15px;
    left: 0;
    text-align: center;
    writing-mode: tb-rl;
    text-transform: uppercase;
    font-weight: 700;
    color: ${theme.colors.gray};
    line-height: 28px;
    letter-spacing: 2px;
    font-size: 12px;

    @media ${theme.mq.fullhd} {
      font-size: 16px;
    }
  }
`

export const Li = styled.li`
  padding-left: 5px;
  margin: 2px 0;
  line-height: 1;
  font-size: 14px;

  @media ${theme.mq.fullhd} {
    font-size: 18px;
  }

  &:first-of-type {
    margin-top: -7px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    position: relative;
    line-height: 1;
    color: ${theme.colors.text};
    padding: 5px;
    width: auto;
    transition: color 0.3s ease;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    max-width: 200px;

    @media ${theme.mq.fullhd} {
      max-width: 280px;
    }

    &:hover,
    &:active,
    &.is-active {
      color: ${theme.colors.primary};
    }

    &.is-active {
      user-select: none;
      cursor: default;
    }
  }
`

export const Link = styled(GatsbyLink)`
  position: relative;
  line-height: 1;
  color: ${theme.colors.text};
  padding: 5px;
  width: auto;
  transition: color 0.3s ease;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  max-width: 200px;

  @media ${theme.mq.fullhd} {
    max-width: 280px;
  }

  &:hover,
  &:active,
  &.is-active {
    color: ${theme.colors.primary};
  }

  &.is-active {
    user-select: none;
    cursor: default;
  }
`

export const A = styled(OutboundLink)`
  position: relative;
  line-height: 1;
  color: ${theme.colors.text};
  padding: 5px;
  width: auto;
  transition: color 0.3s ease;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  max-width: 200px;

  @media ${theme.mq.fullhd} {
    max-width: 280px;
  }

  &:hover,
  &:active,
  &.is-active {
    color: ${theme.colors.primary};
  }

  &.is-active {
    user-select: none;
    cursor: default;
  }
`

export const ColumnRight = styled(Column)`
  display: flex;
  flex-direction: column;
  text-align: right;
  min-width: calc(100% / 3);
`

export const LogoContainer = styled.div`
  align-self: flex-end;
  position: relative;
  width: calc(496px * 0.15);
  height: auto;
  margin-bottom: 70px;
  transition: width 0.5s;

  @media ${theme.mq.laptop} {
    margin-bottom: auto;
  }

  @media ${theme.mq.fullhd} {
    width: calc(496px * 0.2);
  }
`

export const LanguageChoices = styled.div`
  align-self: flex-end;
  font-size: 14px;
  line-height: 22px;
`

export const Span = styled.span`
  color: ${theme.colors.grayLight};
  padding: 0 0.5em;
  line-height: 1;

  &::before {
    content: '·';
    line-height: 1.5;
  }
`

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  padding: 0;
  color: ${theme.colors.text};
  cursor: pointer;
  font-size: 14px;
  line-height: 1.5;

  @media ${theme.mq.fullhd} {
    font-size: 18px;
    line-height: 2;
  }

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    color: ${theme.colors.primary};
  }

  &.is-active {
    user-select: none;
    cursor: default;
  }
`

export const Copyright = styled.div`
  align-self: flex-end;
  font-size: 14px;
  line-height: 1.5;

  @media ${theme.mq.fullhd} {
    font-size: 18px;
    line-height: 2;
  }

  span {
    padding: 0 5px;
  }
`
