import React from 'react'
import PropTypes from 'prop-types'
import LocaleConsumer from '../../context/locale'
import { Container, Video } from './styles'

const origin = () => typeof window !== 'undefined' && window.location.origin

const parseId = url => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : null
}

const VideoYouTube = ({ url }) => (
  <LocaleConsumer>
    {({ locale }) => {
      const options = {
        host: 'https://www.youtube.com',
        playerVars: {
          autoplay: 0,
          controls: 1,
          loop: 1,
          rel: 0,
          showinfo: 0,
          cc_lang_pref: locale,
          origin,
          hl: locale
        }
      }

      const videoId = parseId(url)

      return (
        <Container>
          <Video videoId={videoId} opts={options} />
        </Container>
      )
    }}
  </LocaleConsumer>
)

VideoYouTube.propTypes = {
  url: PropTypes.string.isRequired
}

export default VideoYouTube
