import React from 'react'
import PropTypes from 'prop-types'
import { transparentPixelSrc } from '../../shared/helpers'
import { Container, Video } from './styles'

const VideoSlide = ({ video }) => (
  <Container>
    <Video autoPlay loop muted poster={transparentPixelSrc}>
      <source src={video.file.url} type={video.file.contentType} />
    </Video>
  </Container>
)

VideoSlide.propTypes = {
  video: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
      contentType: PropTypes.string
    })
  }).isRequired
}

export default VideoSlide
