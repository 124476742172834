import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import useSiteMetaData from '../../hooks/use-site-metadata'
import SchemaOrg from './schema-org'

const SEO = ({ isIndex, lang, title, description, meta }) => {
  const siteMetadata = useSiteMetaData()
  const metaDescription = description || siteMetadata.description
  const datePublished = siteMetadata.buildTime
  const { siteUrl, author, social, organization } = siteMetadata

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang
        }}
        title={title}
        titleTemplate={`%s | ${siteMetadata.title}`}
        defer={false}
        meta={[
          {
            rel: 'canonical',
            href: siteUrl
          },
          {
            name: 'description',
            content: metaDescription
          },
          {
            property: 'og:url',
            content: siteUrl
          },
          {
            property: 'og:locale',
            content: lang
          },
          {
            property: 'og:site_name',
            content: title
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'og:description',
            content: metaDescription
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:image',
            content: organization.logo
          },
          {
            name: 'twitter:site',
            content: social.twitter
          },
          {
            name: 'twitter:card',
            content: 'summary'
          },
          {
            name: 'twitter:creator',
            content: author.name
          },
          {
            name: 'twitter:title',
            content: title
          },
          {
            name: 'twitter:description',
            content: metaDescription
          },
          {
            // Pinterest business website claim.
            name: 'p:domain_verify',
            content: social.pinterestDomainVerify
          },
          {
            name: 'apple-mobile-web-app-title',
            content: siteMetadata.title
          },
          {
            name: 'application-name',
            content: siteMetadata.title
          }
        ].concat(meta)}
      />
      <SchemaOrg
        isIndex={isIndex}
        siteUrl={siteUrl}
        title={title}
        defaultTitle={siteMetadata.title}
        description={metaDescription}
        author={author}
        organization={organization}
        datePublished={datePublished}
      />
    </>
  )
}

SEO.propTypes = {
  isIndex: PropTypes.bool,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
}

SEO.defaultProps = {
  isIndex: true,
  lang: 'en_US',
  description: '',
  meta: []
}

export default SEO
