import { theme, styled } from '../../shared/theme'

export const Nav = styled.nav`
  display: none;

  @media ${theme.mq.tablet} {
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 0 1em 0;
  }
`

export const Ul = styled.ul`
  display: flex;
  justify-content: flex-end;
  flex-flow: row;
  margin: 0;
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: block;
    font-size: 14px;
    font-weight: ${theme.fontWeight.medium};
    letter-spacing: 2px;
    color: ${theme.colors.text};
    text-decoration: none;
    transition: color 0.5s ease;
    height: 100%;
    line-height: 1.5;

    @media ${theme.mq.fullhd} {
      font-size: 20px;
    }

    &:hover,
    &:active {
      color: ${theme.colors.primary};
    }
  }

  + li::before {
    content: '//';
    color: ${theme.colors.primary};
    padding: 0 10px;
    line-height: 1;
  }

  &:last-of-type {
    a {
      user-select: none;
      cursor: default;

      &:hover,
      &:active {
        color: ${theme.colors.text};
      }
    }
  }
`
