import YouTube from 'react-youtube'
import { styled } from '../../shared/theme'

export const Container = styled.div`
  padding-top: 56.25%;
`

export const Video = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
