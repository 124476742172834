import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { FaSearch } from 'react-icons/fa'
import { rgba } from 'polished'
import { theme, styled, css } from '../../shared/theme'

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: ${props => (props.show ? 'block' : 'none')};
`

export const Aside = styled.aside`
  position: absolute;
  top: 0;
  min-height: 100%;
  z-index: 1000;
  background-color: ${theme.colors.white};
  transition: left 0.5s, width 0.2s ease;
  box-shadow: 4px 0 5px -5px ${rgba(theme.colors.black, 0.3)};
  overflow: hidden;
  width: calc(100vw - 45px);
  left: ${props => (props.open ? 0 : '-100vw')};

  @media ${theme.mq.tablet} {
    width: 300px;
  }

  @media ${theme.mq.desktop} {
    display: none;
  }
`

export const Search = styled.div`
  position: relative;
  margin: 11px 0;
  line-height: 1;
  z-index: 1;
  padding: 0 20px;
`

export const SearchControl = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  padding: 0 20px;
`

export const Label = styled.label`
  position: relative;
  display: block;
  margin-right: 15px;
  text-align: right;
  font-size: 24px;
  color: ${theme.colors.gray};
  cursor: pointer;
  z-index: 2;

  &:hover,
  &:focus,
  &:active {
    color: ${theme.colors.primary};
  }
`

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 4px;
  width: 14px;
  fill: ${theme.colors.grayLight};
  transition: opacity 0.1s linear;
  opacity: ${props => (props.show && props.show === true ? 1 : 0)};
`

export const Input = styled.input`
  position: relative;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid ${theme.colors.primary};
  box-shadow: none;
  width: 100%;
  padding: 0.5em 0;

  &:active,
  &:hover,
  &:focus {
    border: inherit;
    border-bottom: 2px solid ${theme.colors.primary};
    box-shadow: none;
  }
`

export const Nav = styled.nav`
  line-height: 1;
  margin: 0;
  padding: 30px 20px 0;
`

export const NavTitle = styled.h2`
  margin: 0;
  padding: 1em 0 0.5em;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.75px;
`

export const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Li = styled.li`
  margin: 2px 0;
  border-radius: 2px;
  line-height: 1.25;
`

export const SubUl = styled.ul`
  border-left: 2px solid ${theme.colors.primary};
  margin: 0.75em;
  padding: 0 0 0 0.75em;
  list-style: none;
`

export const SubLi = styled.li`
  display: block;
`

export const InternalLink = styled(Link)`
  display: block;
  color: ${theme.colors.black};
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  padding: 0.5em 0.75em;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    background: none;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.text};
  }
`

export const Span = styled.span`
  margin-left: 5px;
`

export const A = styled(OutboundLink)`
  display: block;
  color: ${theme.colors.black};
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  padding: 0.5em 0.75em;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    background: none;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.text};
  }
`

export const navLink = css`
  display: block;
  color: ${theme.colors.black};
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  padding: 0.5em 0.75em;

  &:hover,
  &:focus,
  &:active,
  &.is-active {
    background: none;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.text};
  }
`
